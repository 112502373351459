::-webkit-scrollbar {
    width: 0.3em;
}

::-webkit-scrollbar-track {
    border-radius: 1em;
}

::-webkit-scrollbar-thumb {
    background: #49a6e9;
    border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #bbb;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    margin: 0;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
